import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const PrivacyPolicy = () => {
    document.title = "PrivacyPolicy  | Upzet - React Admin & Dashboard Template";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Utility" breadcrumbItem="PrivacyPolicy" />

                    <Row>
                        <p>Effective Date: 2023 01 12</p>

                        <p>At FitPuppy.net, we are committed to protecting the privacy and personal information of our users. This Privacy Policy outlines the types of information we collect, how we use and protect that information, and the choices you have regarding your personal data. By using our online fitness tracker site for dogs and humans (&quot;the Site&quot;), you consent to the terms and practices described in this policy.</p>

                        <ol>
                            <li>Information We Collect</li>
                        </ol>

                        <p>1.1 Personal Information: We may collect certain personal information when you interact with our Site, such as your name, email address, age, gender, and location. This information is provided voluntarily by you when you register an account, create a profile, or participate in activities on the Site.</p>

                        <p>1.2 Tracking Information: We automatically collect certain information when you visit our Site, including your IP address, browser type, operating system, referring URLs, and other technical information. This data is collected through cookies, web beacons, and similar technologies to enhance your experience and improve our services.</p>

                        <p>1.3 Fitness Data: In order to provide our fitness insight services, we may collect and store data related to your dog&#39;s activity levels, health, and other fitness-related information. This may include steps taken, distance traveled, calories burned, sleep patterns, and other metrics. We may also collect data regarding your own fitness activities if you choose to track them on our Site.</p>

                        <ol start="2">
                            <li>Use of Information</li>
                        </ol>

                        <p>2.1 Service Provision: We use the information we collect to provide and improve our services, personalize your experience, and communicate with you about your account, updates, and relevant information. We may also use your information to respond to your inquiries, troubleshoot technical issues, and customize the content and features of the Site.</p>

                        <p>2.2 Aggregated Data: We may aggregate and anonymize the information we collect to generate statistical or analytical insights. This aggregated data, which does not identify individual users, may be used for research, analytics, marketing, and other legitimate business purposes.</p>

                        <p>2.3 Marketing and Communication: With your consent, we may use your contact information to send you promotional materials, newsletters, updates, and other communications about our services or those of our partners. You can opt-out of receiving such communications at any time by following the unsubscribe instructions provided in the email or by contacting us directly.</p>

                        <ol start="3">
                            <li>Data Sharing and Disclosure</li>
                        </ol>

                        <p>3.1 Third-Party Service Providers: We may engage trusted third-party service providers to assist us in operating, maintaining, and improving our Site and services. These service providers may have access to your personal information but are strictly bound by confidentiality obligations and are prohibited from using it for any other purposes.</p>

                        <p>3.2 Legal Compliance: We may disclose your personal information if required to do so by law or in response to valid legal requests or court orders. Additionally, we reserve the right to disclose your information to protect our rights, property, or safety, or the rights, property, or safety of others.</p>

                        <ol start="4">
                            <li>Data Security</li>
                        </ol>

                        <p>We implement reasonable security measures to protect the confidentiality, integrity, and availability of your personal information. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee absolute security, and we disclaim any liability for any unauthorized access, loss, or disclosure of your information.</p>

                        <ol start="5">
                            <li>Children&#39;s Privacy</li>
                        </ol>

                        <p>Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected personal information from a child under the age of 13, we will take steps to promptly delete the information from our records.</p>

                        <ol start="6">
                            <li>Your Choices and Rights</li>
                        </ol>

                        <p>6.1 Updating and Accessing Your Information: You have the right to access, update, or correct your personal information. You can review and edit your account information by logging into your profile on the Site or by contacting us directly.</p>

                        <p>6.2 Cookies and Tracking Technologies: You have the ability to manage your cookie preferences and control tracking technologies through your browser settings. However, please note that disabling certain cookies may impact your experience on our Site.</p>

                        <ol start="7">
                            <li>Changes to this Privacy Policy</li>
                        </ol>

                        <p>We reserve the right to modify or update this Privacy Policy from time to time. We will notify you of any significant changes by posting a prominent notice on our Site or by other means. Your continued use of the Site after the effective date of the revised Privacy Policy constitutes your acceptance of the updated terms.</p>

                        <ol start="8">
                            <li>Contact Us</li>
                        </ol>

                        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at info@fitpyppy.net.</p>

                        <p>Thank you for trusting [Insert Website Name]. We are committed to safeguarding your privacy and providing a secure and enjoyable experience on our Site.</p>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PrivacyPolicy;
